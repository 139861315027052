<template>
  <router-view />
</template>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

/*
  Josh's Custom CSS Reset
  https://www.joshwcomeau.com/css/custom-css-reset/
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  margin: 0;
  min-width: 0;
}

input,
button,
textarea,
select {
  font: inherit;
}

img {
  max-width: 100%;
}

:root {
  --NewBrand-Primary-Base: #19b40b;
  --NewBrand-Complementary-Green-Light: #f3f7f3;
  --Feedback-Sucess-Light: #daf1db;
  --Neutral-White-Light: #fff;
  --Brand-Primary-Light: #c1d8c2;
  --Neutral-Black-Base: #1e1e1e;
}

html {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
  width: 100vw;

  body {
    background: var(--NewBrand-Complementary-Green-Light, #f3f7f3);
    -webkit-font-smoothing: antialiased;
    margin: 0;
    padding: 0;
    color: #333;
    width: 100%;
    overflow-x: hidden;

    #app {
      width: 100%;

      button {
        cursor: pointer;
        border: none;
      }
    }
  }
}
</style>
